@import '../../public/base-color.less';

.searchPageJobCard {
  width: 884px;
  // height: 136px;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;

  .spjc-detail {
    width: 884px;
    height: 88px;
    padding: 16px 32px;

    .job {
      margin-bottom: 8px;
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: bold;
      color: @fontColor33;
    }

    .salary {
      margin-right: 16px;
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: bold;
      color: @themeDefaultColor;
    }

    .detail {
      height: 22px;
      line-height: 22px;
      font-size: 14px;
      color: @fontColor4f;
      //max-width: 200px;
      margin-top: 1px;
    }

    .company-name {
      margin-bottom: 8px;
      width: 300px;
      height: 24px;
      font-size: 16px;
      line-height: 24px;
      color: @fontColor33;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .company-detail {
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      color: @fontColor82;
    }

    .company-logo {
      width: 56px;
      height: 56px;
      float: right;
      border-radius: 2px;
      background-color: @fontColorff;
      border: 1px solid @fontColorf2;
      position: relative;

      img {
        position: absolute;
        width: 46px;
        max-height: 46px;
        top: 50%;
        left: 50%;
        transform:translate(-50%,-50%);
      }
    }
  }

  .spjc-welfare {
    width: 884px;
    // height: 48px;
    background-color: #fAfAfA;
    padding: 12px 32px 2px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 0 0 5px 5px;

    .welfare {
      height: 24px;
      padding: 2px 8px;
      margin-right: 8px;
      line-height: 20px;
      font-size: 12px;
      background-color: #fff;
      font-weight: 400;
      color: @fontColor82;
      border-radius: 5px;
      margin-bottom: 10px;
    }

    .time {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      margin-left: auto;
      margin-right: 0px;
      margin-bottom: 10px;
      color: @fontColor82;
    }
  }
}