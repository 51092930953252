@import '../../public/base-color.less';

.footer {
  //height: 421px;
  width: 100%;
  background-color: @fontColorff;

  .footer-row {
    padding-top: 49px;
    width: 1200px;
    margin: 0 auto;
    color: @fontColor82;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;

    p {
      text-align: justify;
      margin-bottom: 0;
    }

    .title {
      font-size: 18px;
      color: @fontColor4f;
      margin-bottom: 13px;
      font-weight: bold;
    }

    .wx-code {
      width: 88px;
      height: 88px;
      margin-right: 8px;
    }

    .phone {
      height: 88px;
      padding: 8px 0;

      p {
        text-align: left;
        word-wrap: normal;

        span {
          font-weight: 700;
          color: @themeDefaultColor;
        }
      }
    }
  }
}

.url-link {
  max-width: 1200px;
  margin: 48px auto 0;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  li {
    list-style: none;

    a {
      color: #4f4f4f;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .line {
    width: 2px;
    height: 8px;
    background-color: #bdbdbd;
  }
}

.ba {
  color: #828282;
  text-align: center;
  padding: 16px 0 1px 0;
  border-top: 1px solid #e0e0e0;
}