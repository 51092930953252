@import '../../public/base-color.less';

.searchPageCompanyCard {
  width: 884px;
  height: 100px;
  background-color: #fff;
  padding: 18px 32px;
  border-radius: 5px;
  cursor: pointer;

  .spcc-company-logo {
    width: 64px;
    height: 64px;
    border-radius: 2px;
    background-color: @fontColorff;
    border: 1px solid @fontColorf2;
    position: relative;

    img {
      position: absolute;
      width: 60px;
      max-height: 60px;
      top: 50%;
      left: 50%;
      transform:translate(-50%,-50%);
    }
  }

  .company-detail {
    margin-top: 6px;
    margin-left: 8px;

    .job {
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      font-weight: bold;
      color: @fontColor33;
    }

    .box {
      width: 100%;
      height: 7px;
    }

    .detail {
      height: 22px;
      line-height: 22px;
      font-size: 14px;
      color: @fontColor4f;
      max-width: 200px;
      text-align: start;
      width: 100%;
    }
  }

  .job-number {
    width: 56px;
    height: 64px;
    padding-top: 5px;

    .number {
      color: @fontColor33;
      width: 22px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      font-size: 18px;
      font-weight: 600;
      margin: auto;
    }

    .box {
      width: 100%;
      height: 8px;
    }

    .text {
      color: @fontColor82;
      width: 56px;
      height: 22px;
      line-height: 22px;
      font-size: 14px;
      font-weight: 400;
    }
  }
}