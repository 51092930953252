.col{
    width: 884px;
}
.mc-card{
    width: 884px;
    margin-bottom: 16px;
}
.mc-second-col{
    margin-left: 16px;
    width: 300px;
    //background-color: white;
}
.page{
    text-align: center;
    margin: auto;
}