@import '../../public/base-color.less';

.visited {
  width: 300px;
  background-color: #fff;
  border-radius: 5px;

  .v-header {
    margin-left: 16px;
    margin-bottom: 16px;
    padding-top: 16px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: @fontColor33;
  }

  .v-divide-first {
    border: 1px solid #E0E0E0;
  }

  .v-job-card-box {
    width: 100%;
    // height: 100%;
    // background-color: red;
    padding: 16px;

    .v-job-name {
      width: 150px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      align-items: center;
      color: @fontColor33;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .v-salary {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: right;
      color: @themeDefaultColor;
    }

    .v-company {
      margin-top: 4px;
      width: 100%;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: @fontColor82;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .v-divide:first-child {
      display: none;
    }

    .v-divide {
      margin-top: 16px;
      margin-bottom: 16px;
      width: 100%;
      border: 1px solid #F2F2F2;
    }
  }
}