@import '../../public/base-color.less';

.jd {
  width: 100%;
  background-color: @fontColorf2;

  .jd-center {
    width: 1200px;
    //height: 1000px;
    margin: 0 auto;
    margin-bottom: 72px;

    .jd-header {
      margin-top: 24px;
      width: 1200px;
      min-height: 200px;
      background-color: @fontColorff;
      background-image: url('./../../assets/job-detail-background.png');
      background-size: cover;
      background-position: center;
      border-radius: 5px;
      padding: 16px 32px;

      .job-status {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        color: @fontColor4f;
      }

      .button-box {
        display: flex;
        height: 32px;

        .collect-job {
          height: 32px;
          //width: 110px;
          margin-right: 16px;
          height: 32px;
          //width: 110px;
          /* Primary/6 */
          cursor: pointer;
          border: 1px solid @themeDefaultColor;
          box-sizing: border-box;
          /* drop-shadow / button-primary */
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 3px;
          padding: 4px 16px 4px 14px;

          .button-detail {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            height: 22px;
            font-size: 14px;
            line-height: 22px;
            color: @themeDefaultColor;

            .button-icon {
              width: 14px;
              height: 14px;
              margin-right: 8px;
              margin-bottom: 2px;
              color: @themeDefaultColor;
            }
          }
        }

        .post-job {
          height: 32px;
          //width: 110px;
          background: @themeDefaultColor;
          /* Primary/6 */
          border: 1px solid @themeDefaultColor;
          box-sizing: border-box;
          /* drop-shadow / button-primary */
          cursor: pointer;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 3px;
          padding: 4px 16px 4px 14px;

          .button-detail {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            height: 22px;
            font-size: 14px;
            line-height: 22px;
            color: white;

            .button-icon {
              width: 14px;
              height: 14px;
              margin-right: 8px;
              margin-bottom: 4px;
            }
          }
        }

      }

      .first-row {
        margin-top: 16px;
        display: flex;

        .job-name {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 30px;
          color: @fontColor33;
        }

        .salary {
          margin-left: 16px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 28px;
          color: #FA6A43;
        }
      }

      .second-row {
        margin-top: 10px;
        color: @fontColor4f;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }

      .third-row {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;

        .welfare {
          border: 1px solid @fontColore0;
          box-sizing: border-box;
          border-radius: 5px;
          padding-left: 8px;
          padding-right: 8px;
          padding-bottom: 2px;
          height: 24px;
          line-height: 22px;
          font-size: 12px;
          margin-bottom: 12px;
          margin-right: 16px;
          color: @fontColor82;
        }
      }
    }

    .jd-first-col {
      width: 884px;
      min-height: 500px;
      background-color: white;
      margin-top: 16px;
      margin-right: 16px;
      padding: 32px;
      border-radius: 5px;

      .title-head {
        width: 100%;
        height: 24px;

        .title-logo {
          width: 4px;
          height: 16px;
          background-color: @themeDefaultColor;
          border-radius: 2px;
          margin-top: 4px;
          margin-right: 8px;
        }

        .title-character {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: @fontColor33;
        }
      }

      .title-detail {
        width: 100%;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: justify;
        margin-top: 16px;
        display: flex;
        white-space: pre-wrap;
        color: @fontColor33;

        .img-locate {
          height: 24px;
          width: 24px;
          margin-right: 4px;

          .locate-icon {
            width: 16px;
            height: 16px;
          }
        }
      }

      .jd-divide {
        border: 1px dashed #F2F2F2;
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }

    .jd-second-col {
      margin-top: 16px;
      width: 300px;
      height: 260px;
      padding: 16px;
      background-color: #fff;
      border-radius: 5px;

      .basic-message {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: @fontColor33;
      }

      .first-row {
        margin-top: 16px;
        display: flex;
        align-items: center;

        .logo {
          width: 48px;
          height: 48px;
          background-color: #fafafa;
          border: 1px solid #e0e0e0;
        }

        .name {
          width: 200px;
          height: 22px;
          margin-left: 12px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: @fontColor4f;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .item {
        float: left;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: @fontColor33;
        margin-top: 16px;

        .icon {
          width: 16px;
          height: 16px;
          margin-right: 12px;
        }
      }
    }
  }
}