@import '../../public/base-color.less';

.hot-company {
  width: 300px;
  background-color: @fontColorff;
  padding: 16px;
  border-radius: 5px;

  .header {
    height: 22px;
    width: 56px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: @themeDefaultColor;
  }

  .card {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  //.divide:first-child {
  //  display: none;
  //}
  //
  //.divide {
  //  margin-left: 56px;
  //  width: 196px;
  //  height: 1px;
  //  background-color: #e0e0e0;
  //}
}