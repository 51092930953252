.bg-img {
  background-size: cover;
  background-position: center;
  width: 100%;
  min-width: 1200px;
  margin: auto;

  .login-box {
    min-width: 1200px;
    max-width: 1200px;
    margin: 0 auto;

    .account-box {
      width: 360px;
      height: 400px;
      margin: 50px auto;
      background-color: #fff;
      border-radius: 10px;
      float: right;
      overflow: hidden;
      transition: all 0.2s;

      .ant-input-affix-wrapper {
        padding: 0 11px;
      }

      .login-tab {
        padding-top: 26px;
        width: 100%;

        .login-tab-item {
          // width: 47% !important;
          padding: 0 !important;
          text-align: center;
          margin: 0 auto;
          font-size: 16px;

          &::after {
            left: 0;
            right: 0;
          }

          color: #828282;

          .ant-menu-title-content {
            width: 180px;
            display: inline-block;
          }
        }

        .ant-menu-item-selected {
          color: #333 !important;
        }
      }

      .login-form {
        padding: 24px 24px 36px;

        .ant-form-item {
          // margin-bottom: 16px;
          .code-input-row {
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            box-sizing: border-box;
            height: 40px;
          }

          .pass-input input {
            height: 38px;
          }

          .ant-input {
            height: 40px;
            border-radius: 5px;
          }
        }

        .get-code {
          line-height: 40px;
          color: #3188F3;
          font-size: 14px;
          text-align: center;
          cursor: pointer;
        }

        .code-login-form-button {
          margin-top: 64px;
        }

        .code-register-form-button {
          border: none;
          color: #828282;
          margin-top: 10px;
        }

        .password-login-form-button {
          margin-top: 8px;
        }

        .login-btn {
          margin-bottom: 0;
        }

        .form-button {
          width: 100%;
          height: 40px;
          box-shadow: none;
          transition: none;
        }
      }
    }

    .register-right {
      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
        border-bottom: none !important;
      }

      // .regist-form-button{
      //     margin-top: 34px;
      // }
    }
  }
}

.bg-img-left {
  background-image: url('./../../assets/login-bg-left.png');
  height: 500px;
}

.bg-img-center {
  background-image: url('./../../assets/login-bg-center.png');
  height: 200px;
}