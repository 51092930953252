@import '../../public/base-color.less';

.recruitment {
  width: 1200px;
  background-color: @fontColorf2;
  min-height: 500px;

  .r-first-col {
    width: 884px;
    margin-right: 16px;

    .r-card {
      margin-bottom: 16px;
      cursor: pointer;
    }

    .page {
      text-align: center;
      margin: auto;
    }
  }

  .r-second-col {
    width: 300px;
    border-radius: 5px;
    background-color: white;
  }
}