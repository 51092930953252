@import '../../public/base-color.less';

.hotCompanyCard {
  width: 284px;

  .hcc-company-logo {
    width: 48px;
    height: 48px;
    border: 1px solid @fontColorf2;
    position: relative;

    img {
      position: absolute;
      width: 46px;
      max-height: 46px;
      top: 50%;
      left: 50%;
      transform:translate(-50%,-50%);
    }
  }

  .hcc-company-detail {

    margin-left: 8px;

    .job {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      font-weight: bold;
      color: @fontColor33;
    }

    .box {
      width: 100%;
      height: 2px;
    }

    .detail {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 22px;
      line-height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: @fontColor4f;
      max-width: 200px;
      text-align: start;
      width: 100%;
    }

    .number {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #000;
    }
  }
}