@import '../../public/base-color.less';

.search-page {
  width: 100%;
  background-color: @fontColorf2;

  .background {
    margin-top: 24px;
    margin-bottom: 63px;

    .center {
      margin: 0 auto;
      width: 1200px;

      .first-col {
        margin-right: 16px;
        width: 884px;
        // height: 1500px;
        .search-box {
          width: 884px;

          .search-city {
            text-align: center;
          }

          .search-input {
            width: 584px;
          }
        }

        .key-word-box {
          margin-bottom: 16px;
          margin-top: 16px;
          width: 884px;
          height: 144px;
          padding: 21px 32px;
          background-color: @fontColorff;
          border-radius: 5px;

          .divide {
            margin-top: 18px;
            margin-bottom: 18px;
          }

          .tag {
            display: flex;

            .title {
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 500, bold;
              font-size: 14px;
              line-height: 22px;
              color: @fontColor33;
              margin-right: 8px;

            }

            .tag-name {
              margin-left: 16px;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: @fontColor82;
              cursor: pointer;
            }

            .tag-name-active {
              margin-left: 16px;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              color: @themeDefaultColor;
            }
          }
        }

        .card {
          margin-bottom: 16px;
        }

        .page {
          text-align: center;
          margin: auto;
        }
      }

      .second-col {
        width: 300px;

        .APP-box {
          background-color: @fontColorff;
          border-radius: 5px;
          width: 100%;
          height: 50px;

          .not-login {
            color: @themeBoldColor;
            line-height: 50px;
            text-align: center;
            font-size: 14px;

            .login-icon {
              width: 24px;
              height: 24px;
              margin-top: 12px;
              margin-bottom: 13px;
              margin-right: 10px;
            }
          }
        }

        .show {
          display: flex;
          height: 50px;

          .APP-box-login {
            background-color: @fontColorff;
            border-radius: 5px;
            width: 100%;
            height: 50px;

            .login {
              background-color: @fontColorff;
              color: @themeDefaultColor;
              line-height: 50px;
              font-size: 14px;
              font-weight: 500;
              border-radius: 5px;
              position: relative;
              padding-left: 57px;

              .login-icon {
                position: absolute;
                top: 17px;
                left: 33px;
                width: 16px;
                height: 16px;

              }
            }
          }

          .app-code-box {
            position: absolute;
            left: 0;
            display: none;
            width: 300px;
            height: 104px;
            background-image: url('./../../assets/code-bg.png');
            background-size: cover;
            background-position: center;
            background-color: @fontColorff;
            padding: 16px;
            border-radius: 5px;
            // margin: 24px auto;
            .app-code {
              float: left;
              width: 72px;
              height: 72px;
            }

            .text {
              float: left;
              padding: 14px 8px;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              color: @fontColor4f;
            }
          }
        }

        @keyframes showCode1 {
          from {
            height: 50px
          }
          to {
            height: 104px
          }
        }
        @keyframes showCode2 {
          from {
            display: none
          }
          to {
            display: block
          }
        }

        .show:hover {
          height: 104px;
          animation: showCode1 0.2s ease-in;

          .app-code-box {
            display: block;
            animation: showCode2 0.1s ease-in 0.2s;
          }
        }

        .user-box {
          margin-top: 16px;
          margin-bottom: 16px;
          width: 300px;
          height: 144px;
          padding: 16px;
          background-color: @fontColorff;
          border-radius: 5px;

          .name {
            margin-left: 16px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: @fontColor33;
          }

          .text {
            position: relative;
            margin-left: 16px;
            margin-top: 4px;
            height: 20px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            /* identical to box height, or 167% */
            display: inline-block;
            align-items: center;
            color: @fontColor82;

            .right-icon {
              position: absolute;
              top: 0;
              right: -20px;
              width: 20px;
            }
          }

          .second-row {
            margin-top: 15px;

            .name {
              margin-left: 24px;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: @fontColor33;
            }

            .text1 {
              position: relative;
              height: 22px;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: @fontColor4f;

              .right-icon {
                position: absolute;
                top: 1px;
                right: -20px;
                width: 20px;
              }
            }

            .divide {
              margin-top: 5px;
              height: 40px;
              width: 1px;
              border: 1px solid @fontColorf2;
            }
          }
        }

        .guide {
          margin-top: 16px;
          margin-bottom: 16px;
          cursor: pointer;
        }
      }
    }
  }
}