.login {
  width: 100%;
  background-color: #f2f2f2;

  .center {
    margin: 0 auto;
    text-align: center;
    width: 1200px;
    padding-top: 48px;

    .title-box {
      margin: 0 auto;

      .title {
        width: 240px;
        height: 32px;
      }
    }

    .job-box-1 {
      background-color: #f2f2f2;
      margin: auto;
      width: 1215px;
      padding-top: 24px;
      padding-bottom: 32px;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
    }

    .search {
      width: 1200px;
      margin: 0 150px 40px 150px;

    }
  }

  .job-box-2 {
    background-color: #f2f2f2;
    margin: auto;
    width: 1300px;
    padding-top: 24px;
    padding-bottom: 56px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
  }
}