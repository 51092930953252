@import '../../public/base-color.less';

.message-box {
  width: 390px;
  height: 198px;
  background-color: #ffffff;
  padding: 16px 24px;
  box-shadow: 0px 8px 40px rgba(130, 130, 130, 0.1);
  border-radius: 5px;
  margin-bottom: 16px;
  margin-right: 15px;
  cursor: pointer;

  .job-detail {
    margin-bottom: 9px;

    .job {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 700;
      color: @fontColor33;
    }

    .salary {
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 700;
      color: @themeDefaultColor;
    }
  }

  .detail {
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    color: @fontColor4f;
    max-width: 250px;
    text-align: start;
    width: 100%;
  }

  .welfare-box {
    margin-top: 9px;
    display: flex;

    .welfare {
      height: 24px;
      padding: 2px 8px;
      margin-right: 8px;
      line-height: 20px;
      font-size: 12px;
      background-color: #fafafa;
      font-weight: 400;
      color: @fontColor82;
      border-radius: 5px;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: @fontColorf2;
    margin-top: 12px;
    margin-bottom: 16px;
  }

  .company-box {
    width: 100%;
    height: 48px;
    display: flex;
    text-align: start;

    .company-logo {
      width: 48px;
      height: 48px;
      border: 1px solid @fontColorf2;
      border-radius: 2px;
      position: relative;

      img {
        position: absolute;
        width: 44px;
        max-height: 44px;
        top: 50%;
        left: 50%;
        transform:translate(-50%,-50%);
      }
    }

    .company-detail {
      margin-left: 8px;

      .job {
        font-weight: 500;
      }

      .box {
        width: 100%;
        height: 4px;
      }
    }
  }
}