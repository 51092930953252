@import '../../public/base-color.less';

.search-box {
  width: 900px;
  height: 50px;
  background-color: @fontColorff;
  border-radius: 5px;
  position: relative;

  .search-left {
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    border: 2px solid @themeDefaultColor;
    height: 50px;
    line-height: 46px;

    .search-city {
      width: 160px;
    }

    .search-input {
      width: 600px;
    }
  }

  .search-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 140px;
    height: 50px;
    font-size: 18px;
    background-color: @themeDefaultColor;
  }

  //.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  //  width: 100%;
  //  height: 50px;
  //  padding: 11px;
  //}

  .keyword {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: @fontColor4f;
  }


  .ant-input-group .ant-input {
    text-align: start;
    height: 30px;
  }
}

.ant-select-selector:hover {
  color: @themeDefaultColor;
}
