@import '../../public/base-color.less';

.company-message-box {
  width: 390px;
  height: 400px;
  background-color: @fontColorff;
  padding: 24px;
  box-shadow: 0 8px 40px rgba(130, 130, 130, 0.1);
  border-radius: 5px;
  margin-bottom: 16px;
  margin-right: 15px;

  .company-box {
    width: 100%;
    height: 48px;
    display: flex;
    margin-bottom: 24px;
    cursor: pointer;

    .company-logo {
      width: 48px;
      height: 48px;
      border: 1px solid @fontColorf2;
      border-radius: 2px;
      position: relative;

      img {
        position: absolute;
        width: 44px;
        max-height: 44px;
        top: 50%;
        left: 50%;
        transform:translate(-50%,-50%);
      }
    }

    .company-detail {
      margin-left: 8px;

      .job {
        text-align: start;
        max-width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 24px;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
        color: @fontColor33;
      }

      .box {
        width: 100%;
        height: 4px;
      }

      .detail {
        height: 22px;
        line-height: 22px;
        font-size: 14px;
        color: @fontColor4f;
        max-width: 200px;
        text-align: start;
        width: 100%;
      }
    }
  }

  .job-detail {
    margin-bottom: 9px;

    .job {
      max-width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: bold;
      color: @fontColor33;
    }

    .salary {
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: bold;
      color: @themeDefaultColor;
    }
  }

  .detail {
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    color: @fontColor4f;
    max-width: 200px;
    text-align: start;
    width: 100%;
  }

  .position-box {
    height: 240px;
  }

  .job-box {
    display: flex;
    flex-direction: column;
    width: 342px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: @fontColorf2;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .more {
    background-color: @fontColorff;
    margin-top: 16px;
    width: 116px;
    height: 32px;
    border: 1px solid @themeDefaultColor;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 3px;
    color: @themeDefaultColor;
    cursor: pointer;
  }
}