@import '../../public/base-color.less';

.middle {
  padding: 24px 0 72px 0;
  width: 100%;
  background-color: @fontColorf2;

  .center {
    width: 1200px;
    margin: 0 auto;

    .I-first-col {
      width: 884px;
      background-color: white;
      margin-right: 16px;
      border-radius: 5px;

      .preview-box {
        width: 884px;
        height: 56px;
        padding: 16px 32px;

        .title-box {
          display: flex;
          align-items: center;

          .title-header {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            height: 24px;
            color: @fontColor33;
          }

          .title-time {
            margin-left: 8px;
            height: 20px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: @fontColor82;
          }
        }

        .preview {
          cursor: pointer;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: @themeDefaultColor;
        }
      }

      .title-divider {
        border: 1px solid #E0E0E0;
      }

      .resume-box {
        width: 100%;
        padding: 16px 32px;
      }

      .information {
        display: flex;
        flex-direction: row;
        align-items: center;

        .avater {
          width: 88px;
          height: 88px;
          // background-color: red;
        }

        .detail-box {
          width: 716px;
          margin-left: 16px;

          .detail-1 {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 8px;

            .name {
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 24px;
              color: @fontColor33;
              cursor: pointer;
            }
          }
        }
      }
    }

    .I-second-col {
      width: 300px;

      .resume-doc {
        padding: 16px;
        background-color: #fff;
        border-radius: 5px;

        .doc-header {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
        }

        .doc {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .doc-name {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: @fontColor82;
            width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .my {
        width: 300px;
        background-color: #fff;
        padding: 16px;
        margin-top: 16px;
        border-radius: 5px;

        .my-item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .my-name {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: @fontColor33;
          }

          .right-icon {
            width: 24px;
            height: 24px;
          }
        }

        .my-divide {
          border: 1px solid #E0E0E0;
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }
    }
  }

  .divider {
    margin: 16px 0px;
    border: 1px dashed #E0E0E0;
  }

  .title-head {
    width: 100%;
    height: 24px;

    .title-logo {
      width: 4px;
      height: 16px;
      background-color: @themeDefaultColor;
      border-radius: 2px;
      margin-top: 4px;
      margin-right: 8px;
    }

    .title-character {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: @fontColor33;
    }
  }

  .item-box {
    margin-top: 16px;
    width: 100%;

    .item {
      padding: 9px 12px;

      .edit-box {
        display: none;
      }
    }

    .item:hover {
      background-color: @fontColorfa;
      position: relative;

      .edit-box {
        margin-left: 6px;
        display: flex;
        width: 800px;
        position: absolute;
        text-align: right;
        justify-content: flex-end;
      }
    }
  }

  .edit {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: @themeDefaultColor;
    cursor: pointer;
  }

  .del {
    margin-left: 8px;
    margin-right: 6px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #e63900;
    cursor: pointer;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  .icon-2 {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }

  .character33 {
    display: flex;
    align-items: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: @fontColor33
  }

  .character33500 {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: @fontColor33
  }

  .character82 {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: @fontColor82;
    margin-left: 16px;
  }

  .divide-box {
    margin-top: 10px;
    margin-bottom: 9px;
  }

  .skill-box {
    display: flex;
    flex-wrap: wrap;

    .skill {
      padding: 8px 12px;
      background: #F2F2F2;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 5px;
      margin-bottom: 10px;
      margin-right: 16px;
    }
  }
}