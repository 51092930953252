@import '../../public/base-color.less';

.cd {
  width: 100%;
  background-color: @fontColorf2;

  .cd-center {
    // height: 1000px;
    padding-top: 24px;
    padding-bottom: 72px;
    width: 1200px;
    margin: 0 auto;

    .cd-header {
      width: 1200px;
      height: 152px;
      // background-color: blue;
      background-image: url('./../../assets/company-detail-background.png');
      background-size: cover;
      background-position: center;
      padding: 24px 32px;
      border-radius: 5px 5px 0 0;

      .cd-company-logo {
        width: 104px;
        height: 104px;
        border-radius: 2px;
        border: 1px solid @fontColorf2;
        background-color: @fontColorff;
      }

      .cd-company-detail {
        margin: 22px 0px 22px 31px;
        height: 60px;

        .cd-job {
          font-family: 'PingFang SC';
          font-size: 20px;
          line-height: 28px;
          color: @fontColor33;
          font-weight: 500;
        }

        .cd-box {
          height: 10px;
        }

        .cd-detail {
          font-family: 'PingFang SC';
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: @fontColor4f;
        }
      }

      .cd-job-number {
        height: 60px;
        margin: 22px 82px;
        text-align: center;

        .cd-number {
          font-family: 'PingFang SC';
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          color: @themeDefaultColor;
        }

        .cd-box {
          height: 10px;
        }

        .cd-text {
          font-family: 'PingFang SC';
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: @themeDefaultColor;
        }
      }
    }

    .badge {
      display: inline;
      height: 20px;
      font-size: 12px;
      margin: 2px 4px 2px 4px;
      padding: 0px 6px;
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      background-color: @fontColore0;
      border-radius: 10px;
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    background-color: #fff;
    border-radius: 0 0 5px 5px;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    margin-left: 32px;
  }
}