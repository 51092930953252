@import '../../public/base-color.less';

.head {
  width: 100%;
  height: 56px;
  line-height: 56px;
  background-color: @themeBoldColor;

  .navRow {
    width: 1200px;
    height: 56px;
    margin: 0 auto;

    .znc-logo {
      height: 24px;
      width: 168px;
    }
    .ant-dropdown-menu{
        margin-top: 16px;
    }
  }

  .right {
    float: right;
  }

  .page {
    color: white;
    width: 70px;
  }

  .page:active {
    color: #3188f3;
  }

  a {
    display: inline-block;
    padding: 0 8px;
    height: 56px;
    font-size: 14px;
    font-weight: 500;
  }

  a:hover {
    background-color: #12244f;
  }
}

button[ant-click-animating-without-extra-node]:after {
  border: 0 none;
  opacity: 0;
  animation: none 0 ease 0 1 normal;
}