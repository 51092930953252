@import '../../public/base-color.less';

.recruitment-card {
  width: 884px;
  background-color: white;
  padding: 20px 32px;
  border-radius: 5px;

  .job {
    margin-bottom: 8px;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
    color: @fontColor33;
  }

  .salary {
    margin-right: 16px;
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
    color: @themeDefaultColor;
  }

  .detail {
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    color: @fontColor4f;
    max-width: 250px;
    margin-top: 1px;
  }

  .rc-welfare {
    // height: 48px;
    display: flex;
    flex-wrap: wrap;

    .welfare {
      height: 24px;
      padding: 2px 8px;
      margin-right: 8px;
      line-height: 20px;
      font-size: 12px;
      background-color: @fontColorfa;
      font-weight: 400;
      color: @fontColor82;
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }
}