@import '../../public/base-color.less';
.login-guide-box{
    width: 300px;
    height: 280px;
    background-color: @themeDefaultColor;
    border-radius: 5px;
    .guide-detail{
        width: 216px;
        height: 144px;
        margin: auto;
        padding-top: 40px;
        .first-step{
            margin-right: 16px;
            width: 32px;
            height: 32px;
            border-radius: 32px;
            background-color: #fff;
            line-height: 32px;
            text-align: center;
            color: @themeDefaultColor;
        }
        .step{
            margin-right: 16px;
            border: 1px solid #FFFFFF;
            width: 32px;
            height: 32px;
            border-radius: 32px;
            line-height: 32px;
            text-align: center;
            color: #fff;
        }
        .guide-text{
            height: 32px;
            line-height: 32px;
            color: #fff;
            font-size: 16px;
        }
        .divide{
            width: 32px;
            height: 24px;
            font-size: 12px;
            text-align: center;
            color: #fff;
        }
    }
    .button{
        margin-top: 40px;
        width: 250px;
        height: 32px;
        background-color: #fff;
        color: @themeDefaultColor;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;
    }
}