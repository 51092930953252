@import '../../public/base-color.less';

.my {
  width: 100%;
  background-color: #F2F2F2;


  .my-center {
    width: 1200px;
    min-height: 500px;
    margin: 20px auto 72px auto;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    background-color: #fff;
    border-radius: 5px;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    margin-left: 32px;
  }
}