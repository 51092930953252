@import '../../public/base-color.less';

.company-detail-introduction {
  width: 1200px;

  .cdi-first-col {
    width: 884px;
    background-color: #fff;
    margin-right: 16px;
    padding: 32px;
    border-radius: 5px;

    .cdi-box {
      width: 100%;
      height: 100%;

      .title-head {
        width: 100%;
        height: 24px;

        .title-logo {
          width: 4px;
          height: 16px;
          background-color: @themeDefaultColor;
          border-radius: 2px;
          margin-top: 4px;
          margin-right: 8px;
        }

        .title-character {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: @fontColor33;
        }
      }

      .title-detail {
        width: 100%;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: justify;
        margin-top: 16px;
        margin-bottom: 16px;
        word-break: break-word;
        display: flex;
        color: @fontColor33;

        .img-locate {
          height: 24px;
          width: 24px;
          margin-right: 4px;

          .locate-icon {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .cdi-second-col {
    width: 300px;
    background-color: #fff;
    padding: 16px;
    border-radius: 5px;

    .title-head {
      width: 100%;
      height: 24px;

      .title-logo {
        width: 4px;
        height: 16px;
        background-color: @themeDefaultColor;
        border-radius: 2px;
        margin-top: 4px;
        margin-right: 8px;
      }

      .title-character {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: @fontColor33;
      }
    }

    .carousel {
      margin-top: 16px;

      .carousel-img {
        height: 160px;
        border-radius: 2px;
      }
    }
  }
}