.col{
    width: 884px;
}
.mp-card{
    width: 884px;
    min-height: 900px;
    margin-bottom: 16px;
}
.mp-second-col{
    margin-left: 16px;
    width: 300px;
    //background-color: white;
}
.page{
    text-align: center;
    margin: auto;
}